var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("reward.title") },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.title,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "title", $$v)
              },
              expression: "listQuery.title"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: { placeholder: _vm.$t("reward.enabled"), clearable: "" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.enabled,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "enabled", $$v)
                },
                expression: "listQuery.enabled"
              }
            },
            _vm._l(_vm.enableTypeOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.value, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.sort,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "sort", $$v)
                },
                expression: "listQuery.sort"
              }
            },
            _vm._l(_vm.sortOptions, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { size: "small", type: "warning", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "margin-horizontal",
              attrs: { to: "/campaigns/add/" }
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-edit",
                    size: "small"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.form.add")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "sort-change": _vm.sortChange }
        },
        [
          _c("el-table-column", {
            attrs: { width: "50", align: "center", label: "ID", prop: "id" }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              align: "center",
              label: "Title",
              prop: "title"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.campaignsImage.length > 0
                      ? _c("el-image", {
                          staticStyle: { width: "80px", height: "80px" },
                          attrs: {
                            src: scope.row.campaignsImage[0].image,
                            fit: "fill"
                          }
                        })
                      : _vm._e(),
                    _c("br"),
                    _c("enabled", {
                      attrs: { url: "/campaigns/" + scope.row.id },
                      model: {
                        value: scope.row.enabled,
                        callback: function($$v) {
                          _vm.$set(scope.row, "enabled", $$v)
                        },
                        expression: "scope.row.enabled"
                      }
                    }),
                    _vm._v(" " + _vm._s(scope.row.title) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "160",
              align: "center",
              label: "Redeemable Points",
              prop: "redeemablePoints"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("inr", { attrs: { number: scope.row.redeemablePoints } })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "160", align: "center", label: "Validity" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(scope.row.validFrom, "DD-MM-YYYY")
                        ) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(" till "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(scope.row.validTill, "DD-MM_YYYY")
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              width: "120",
              align: "center",
              label: "Redeem Limit",
              prop: "redeemLimit"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.redeemLimit
                      ? _c("span", [_vm._v(_vm._s(scope.row.redeemLimit))])
                      : _c("span", [_vm._v(" - ")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.createdDate"),
              width: "180",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            scope.row.createdTimestamp,
                            "DD-MM-YYYY hh:mm a"
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Actions", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      { attrs: { to: "/campaigns/redeems/" + scope.row.id } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "warning",
                              size: "small",
                              icon: "el-icon-s-claim"
                            }
                          },
                          [_vm._v(" Redeems List ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/campaigns/edit/" + scope.row.id } },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "success",
                            size: "small",
                            icon: "el-icon-edit"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }